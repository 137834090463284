<template>
  <div class="container">
    <nav class="navbar-left">
      <a href="news.asp">Pregled</a>
      <a href="contact.asp">Naročila</a>
      <a href="about.asp">Partnerji</a>
      <a href="about.asp">Sestanki</a>
      <a href="about.asp">Šifrant</a>
    </nav>
    <div class="navbar-center">

    </div>
    <nav class="navbar-right">
      <a href="nastavitve">Nastavitve</a>
      <a href="login">Odjavi se</a>
    </nav>
  </div>
</template>

<script>

</script>

<style scoped>
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    background-color: white;
    color: black;
    font-size: 20px;
    box-shadow: 0px 0px 20px 0px black;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: black;
  margin-right: 10px;
  margin-left: 10px;
}

a:hover {
  color: #1E90FF;
  transition: 0.2s;
}
</style>
