import { reactive } from 'vue';

interface Store {
    username: string | null;
    updateName: (name: string) => void;
    hasLogin: boolean;
    updateHasLogin: (value: boolean) => void;
}

export const store: Store = reactive({
    username: null as string | null,
    updateName(name: string) {
        this.username = name;
    },
    hasLogin: false as boolean,
    updateHasLogin(value: boolean) {
        this.hasLogin = value;
    }
});
