<template>
  <NavBar/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';

@Options({
  components: {
    NavBar
  },
})
export default class HomeView extends Vue {}
</script>

<style>
body {
  margin: 0;
  background-color: #353C40;
}
</style>